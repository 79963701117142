import React, { ChangeEvent, useRef } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import styles from '../main/styles.module.scss';
import ImageUploader from 'react-images-upload';

interface ButtonGroupProps {
  handleImage: (e: ChangeEvent) => void;
  querying: boolean;
}

const ButtonGroup = ({ handleImage, querying }: ButtonGroupProps) => {
  const pickImgButton = useRef<any>();

  const renderOcr = () => {
    return (
      <>
        {querying ? (
          <CircularProgress />
        ) : (
          <Button
            className={styles['button']}
            variant="contained"
            color="primary"
            onClick={() => {
              pickImgButton.current.click();
            }}
          >
            Pick a Photo
          </Button>
        )}

        <input
          ref={pickImgButton}
          type="file"
          hidden={true}
          accept="image/*"
          onChange={handleImage}
        />
      </>
    );
  };

  return renderOcr();
};

export default ButtonGroup;
