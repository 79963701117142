import React, { useEffect, useState } from 'react';
import 'react-html5-camera-photo/build/css/index.css';
import { ocrApi } from '../../api/api';
import styles from './styles.module.scss';
import ButtonGroup from '../button-group';
import OcrPage from '../ocr';
import LatexPage from '../latex';
import { Snackbar } from '@material-ui/core';
import Compressor from 'compressorjs';

// @ts-ignore

interface OcrResult {
  latex: string;
  confidence: number;
  rotate: number;
}

const Main = () => {
  const [img, setImg] = useState('');
  const [ocrResult, setOcrResult] = useState<OcrResult>({
    latex: '',
    confidence: 0,
    rotate: 0,
  });
  const [snackBarInfo, setSnackBarInfo] = useState('');
  const [querying, setLoading] = useState(false);

  const a = async () => {
    if (img === '') {
      return;
    }
    setLoading(true);
    const result: OcrResult = await ocrApi(img);
    setLoading(false);
    setOcrResult(result);
  };

  useEffect(() => {
    a();
  }, [img]);

  const setQuerying = (l: boolean) => {
    setLoading(l);
  };

  const openSnackBar = (info: string) => {
    setSnackBarInfo(info);
  };

  const pickImage = async (e: any) => {
    const image = e.target.files[0];
    new Compressor(image, {
      quality: 0.6,
      success(file: Blob) {
        const reader = new FileReader();
        reader.onloadend = () => {
          if (typeof reader.result != 'string') return;
          setImg(reader.result);
        };
        reader.readAsDataURL(file);
      },
    });
  };

  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={snackBarInfo !== ''}
        autoHideDuration={4000}
        onClose={() => setSnackBarInfo('')}
        message={snackBarInfo}
      />
      <div className={styles.container}>
        <OcrPage img={img} rotate={ocrResult?.rotate} />
        <LatexPage
          openSnackBar={openSnackBar}
          querying={querying}
          setQuerying={setQuerying}
          latexResult={ocrResult.latex}
          confidence={ocrResult?.confidence}
        />
      </div>
      <div className={styles['button-container']}>
        <ButtonGroup querying={querying} handleImage={pickImage} />
      </div>
    </>
  );
};

export default Main;
