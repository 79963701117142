import React from 'react';
import PlaceHolder from 'assets/1.png';
import styles from './styles.module.scss';

interface OcrPageProps {
  img: string;
  rotate?: number;
}

const OcrPage = ({ img, rotate }: OcrPageProps) => {
  return (
    <>
      <div className={styles['img-container']}>
        <img
          style={{
            transform: `rotate(-${rotate}deg)`,
            marginTop: img === '' ? 100 : 0,
          }}
          src={img === '' ? PlaceHolder : img}
          alt={'image'}
          className={styles['img']}
        />{' '}
      </div>
    </>
  );
};

export default OcrPage;
