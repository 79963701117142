import React, { useEffect, useRef, useState } from 'react';
import { Button, CircularProgress, TextField } from '@material-ui/core';
import { latexApi } from '../../api/api';
import Latex from 'react-latex-next';
import 'katex/dist/katex.min.css';
// @ts-ignore
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styles from './styles.module.scss';

type LatexResult = { title: string; result_img: string[] }[];
interface LatexPageProps {
  latexResult: string;
  confidence?: number;
  setQuerying: (l: boolean) => void;
  querying: boolean;
  openSnackBar: (info: string) => void;
}

const LatexPage = ({
  latexResult,
  confidence,
  setQuerying,
  querying,
  openSnackBar,
}: LatexPageProps) => {
  const [latex, setLatex] = useState(latexResult);
  const [calculate, setCalculate] = useState<LatexResult | null>(null);
  useEffect(() => {
    setLatex(latexResult);
  }, [latexResult]);

  const queryLatex = async (l?: string) => {
    if (!l) {
      openSnackBar('latex can not be empty');
      return;
    }
    setCalculate(null);
    try {
      setQuerying(true);
      const result: LatexResult = await latexApi(l);
      setCalculate(result);
    } catch (e) {
      openSnackBar(e.data['error']);
    } finally {
      setQuerying(false);
    }
  };

  useEffect(() => {
    setLatex(latexResult);
    if (confidence != null && confidence > 0.5) {
      queryLatex(latexResult);
    }
  }, [latexResult]);

  return (
    <div>
      <div className={styles['container']}>
        <div className={styles['latex']}>
          <Latex>{latex ? `$` + latex + `$` : ''}</Latex>
        </div>
        <TextField
          id="outlined-multiline-flexible"
          key={latexResult}
          label="Latex"
          multiline
          fullWidth={true}
          value={latex}
          onChange={(e) => {
            let a: string = e.target.value;
            setLatex(a);
            confidence = undefined;
          }}
          variant="outlined"
        />
      </div>
      <div
        className={styles['hint']}
        hidden={confidence == null || confidence === 0 || confidence > 0.5}
      >
        We are not sure about the Latex, please check it manually.
      </div>
      <div className={`${styles['cal-button']}`}>
        <CopyToClipboard text={latex} onCopy={() => openSnackBar('copied')}>
          <Button
            variant="outlined"
            color="primary"
            size={'large'}
            disabled={latex == null || latex === ''}
          >
            Copy to clipboard
          </Button>
        </CopyToClipboard>
        {querying ? (
          <CircularProgress className={styles['spin']} />
        ) : (
          <Button
            variant="outlined"
            color="primary"
            size={'large'}
            disabled={latex == null || latex === '' || querying}
            onClick={() => {
              if (latex !== '') queryLatex(latex);
            }}
            className={styles['button']}
          >
            {querying ? 'Calculating' : 'Calculate'}
          </Button>
        )}
      </div>
      <div className={styles['result']}>
        {calculate?.map((e, i) => {
          return (
            <div key={i}>
              <div className={styles['title']}>{e.title}</div>
              <div className={styles['imgs']}>
                {e.result_img.map((img_64) => (
                  <div>
                    <img src={img_64} alt={''} />
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
      <div
        hidden={calculate == null || calculate.length === 0}
        className={styles['footer']}
      >
        ==== EOF ====
        <br />
      </div>
      <div
        hidden={calculate == null || calculate?.length !== 0}
        className={styles['footer']}
      >
        Sorry, we did not get any result.
      </div>
    </div>
  );
};

export default LatexPage;
