import axios from 'axios';
import env from 'react-dotenv';

let ENDPOINT = '';
switch (env.REACT_APP_STATUS) {
  case 'dev':
    ENDPOINT = 'http://127.0.0.1:8088/';
    break;

  case 'production':
    ENDPOINT = 'https://cam.teaforence.com/';
    break;

  case 'test':
    ENDPOINT = 'https://cam.xn--pda.win/';
    break;
}

export const ocrApi = async (data: string) => {
  try {
    const response: { data: OcrResult } = await axios.post(ENDPOINT + 'ocr/', {
      src: data,
    });
    return response.data;
  } catch (e) {
    throw e;
  }
};

interface OcrResult {
  latex: string;
  confidence: number;
  rotate: number;
}
export const latexApi = async (data: string) => {
  try {
    const response = await axios.post(ENDPOINT + 'latex/', {
      latex: data,
    });
    return response.data.results;
  } catch (e) {
    throw e;
  }
};
